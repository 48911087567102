<template>
  <div tabindex="0" role="button" class="collapse" @click="toggleCollapse">
    <i class="material-icons">{{ materialIcon }}</i>
    <div class="badge" :class="materialIcon" v-if="showBadge">
      {{ countBadge }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countBadge: {
      type: Number,
      default: 0
    },
    showBadge: {
      type: Boolean,
      default: true
    },
    materialIcon: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sectionOpen: false
    };
  },
  methods: {
    toggleCollapse() {
      this.sectionOpen = !this.sectionOpen;
      this.$emit('changeSectionOpen', this.sectionOpen);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../app.scss';

.collapse {
  position: relative;
  color: #d9dde0;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;

  .material-icons {
    font-size: 30px;
  }
}

.badge {
  height: 16px;
  width: 16px;
  background-color: $--color-talmundo-yellow;
  text-align: center;
  border-radius: 50%;
  padding: 3px 2px;
  color: white;
  top: -5px;
  right: -5px;
  position: absolute;
  font-size: 10px;
}
</style>
